import React from 'react';
import Async from './async';
import DefaultBusinessLayout from '../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business/gcse"
    exact
    path="/business/gcse"
    component={Async}
    contactsalesbutton={false}
  />,
];
