import React from 'react';
import {useTranslation} from 'react-i18next';
import './index.css';

export default function NftFooter({terms, is2b, language}) {
  const {t} = useTranslation();

  return (
    <div className="nft-footer">
      <div>
        2022@&nbsp;&nbsp;
        {t('business:c.l.f.copyright', 'Perfect Corp. All Rights Reserved.')}
      </div>
      {!terms && (
        <a
          className="nft-footer--terms"
          href={`${language}/nft/terms?is2b=${is2b}`}
          target="_blank"
        >
          <div>Terms of use</div>
          <img
            alt=""
            src={require('../../../../assets/images/nft/terms_arrow.svg')}
          />
        </a>
      )}
    </div>
  );
}
