import React from 'react';
import {Trans} from 'react-i18next';

const links = {
  home: {
    id: 'our_apps',
    text: <Trans i18nKey="consumer:u.c.applinks.home">Our Apps</Trans>,
    link: '/consumer/apps',
    i18nKey: 'consumer:u.c.applinks.home',
  },
  all: {
    id: 'all',
    text: <Trans i18nKey="consumer:u.c.applinks.all">All Apps</Trans>,
    link: '/consumer/apps',
    i18nKey: 'consumer:u.c.applinks.all',
  },
  allApps: {
    id: 'all_apps',
    iosUrl: 'https://apps.apple.com/us/developer/perfect-corp/id1018779369',
    androidUrl:
      'https://play.google.com/store/apps/dev?id=5286790089270932286&hl=en',
  },
  ycf: {
    id: 'youcam_fun',
    bgColor: '#efb407',
    text: <Trans i18nKey="consumer:u.c.applinks.ycf">YouCam Fun</Trans>,
    link: '/consumer/apps/ycf',
    i18nKey: 'consumer:u.c.applinks.ycf',
    iosUrl:
      'https://apps.apple.com/app/id1136617049?pt=117886073&ct=PFwebsite_consumer&mt=8',
    cnIosUrl: 'https://apps.apple.com/cn/app/id1136617049',
    androidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycf&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_consumer',
    cnAndroidUrl: 'https://shouji.baidu.com/software/29148122.html',
    bannerIOSUrl:
      'https://apps.apple.com/us/app/youcam-fun-live-face-filters/id1136617049?pt=117886073&ct=PFWebsite-mobile-banner&mt=8',
    bannerAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycf&referrer=utm_source%3DPFWebsite%26utm_medium%3DPFWebsite%26utm_campaign%3DPFWebsite-mobile-banner',
    version: '1.15.10',
  },
  ycn: {
    id: 'youcam_nail',
    bgColor: '#ff844a',
    text: <Trans i18nKey="consumer:u.c.applinks.ycn">YouCam Nail</Trans>,
    link: '/consumer/apps/ycn',
    i18nKey: 'consumer:u.c.applinks.ycn',
    iosUrl:
      'https://apps.apple.com/app/id1051710880?pt=117886073&ct=PFwebsite_consumer&mt=8',
    cnIosUrl: 'https://apps.apple.com/cn/app/id1051710880',
    androidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycn&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_consumer',
    cnAndroidUrl: 'https://shouji.baidu.com/software/29147030.html',
    bannerIOSUrl:
      'https://apps.apple.com/app/id1051710880?pt=117886073&ct=PFwebsite_consumer&mt=8',
    bannerAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycn&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_consumer',
    appsPageBannerIOSUrl:
      'https://apps.apple.com/app/id1051710880?pt=117886073&ct=PFwebsite_consumer&mt=8',
    appsPageBannerAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycn&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_consumer',
    appsPageBlogBannerIOSUrl:
      'https://apps.apple.com/app/id1051710880?pt=117886073&ct=PFwebsite_ycnblog&mt=8',
    appsPageBlogBannerAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycn&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_ycnblog',
    version: '1.25.11',
  },
  ycp: {
    id: 'youcam_perfect',
    bgColor: '#882075',
    text: <Trans i18nKey="consumer:u.c.applinks.ycp">YouCam Perfect</Trans>,
    link: '/consumer/apps/ycp',
    i18nKey: 'consumer:u.c.applinks.ycp',
    iosUrl: 'https://youperfect.page.link/PFwebsite_consumer_ycp_download',
    cnIosUrl: 'https://apps.apple.com/cn/app/id1522816615',
    androidUrl:
      'https://youperfect.page.link/PFwebsite_consumer_ycp_download_android',
    cnAndroidUrl: 'https://shouji.baidu.com/software/29377979.html',
    bannerIOSUrl: 'https://youperfect.page.link/PFwebsite_top_banner_iOS',
    bannerAndroidUrl: 'https://youperfect.page.link/PFwebsite_top_banner_And',
    appsPageBannerIOSUrl: 'https://youperfect.page.link/PFwebsite_top_prod_iOS',
    appsPageBannerAndroidUrl:
      'https://youperfect.page.link/PFwebsite_top_prod_And',
  },
  ycv: {
    id: 'youcam_video',
    bgColor: '#212529',
    text: <Trans i18nKey="consumer:u.c.applinks.ycv">YouCam Video</Trans>,
    link: '/consumer/apps/ycv',
    i18nKey: 'consumer:u.c.applinks.ycv',
    iosUrl: 'https://youvideo.page.link/PFwebsite_top_prod_iOS',
    androidUrl: 'https://youvideo.page.link/PFwebsite_ycv_android',
    bannerIOSUrl: 'https://youvideo.page.link/PFwebsite_top_banner_iOS',
    bannerAndroidUrl: 'https://youvideo.page.link/PFwebsite_top_banner_And',
    appsPageBannerIOSUrl: 'https://youvideo.page.link/PFwebsite_top_prod_iOS',
    appsPageBannerAndroidUrl:
      'https://youvideo.page.link/PFwebsite_top_prod_And',
  },
  ycc: {
    id: 'youcam_cut',
    bgColor: '#212529',
    text: <Trans i18nKey="consumer:u.c.applinks.ycc">YouCam Cut</Trans>,
    link: '/consumer/apps/ycc',
    i18nKey: 'consumer:u.c.applinks.ycc',
    androidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycv&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_consumer',
    bannerUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycv&referrer=utm_source%3DPFWebsite%26utm_medium%3DPFWebsite%26utm_campaign%3DPFWebsite-mobile-banner',
    bannerAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.perfectcorp.ycv&referrer=utm_source%3DPFwebsite%26utm_medium%3DPFwebsite%26utm_campaign%3DPFwebsite_consumer',
  },
  ymk: {
    id: 'youcam_makeup',
    bgColor: '#e53874',
    text: <Trans i18nKey="consumer:u.c.applinks.ymk">YouCam Makeup</Trans>,
    link: '/consumer/apps/ymk',
    i18nKey: 'consumer:u.c.applinks.ymk',
    iosUrl: 'https://youmakeup.page.link/PFwebsite_consumer_download',
    cnIosUrl: 'https://apps.apple.com/cn/app/id1522818382',
    androidUrl: 'https://youmakeup.page.link/PFwebsite_ymk_android',
    cnAndroidUrl: 'https://shouji.baidu.com/software/29440613.html',
    bannerIOSUrl: 'https://youmakeup.page.link/PFwebsite_top_banner_iOS',
    bannerAndroidUrl: 'https://youmakeup.page.link/PFwebsite_top_banner_And',
    appsPageBannerIOSUrl: 'https://youmakeup.page.link/PFwebsite_top_prod_iOS',
    appsPageBannerAndroidUrl:
      'https://youmakeup.page.link/PFwebsite_top_prod_And',
    version: '5.62.0',
  },
  yce: {
    id: 'youcam_edit',
    bgColor: '#e53874',
    text: <Trans i18nKey="consumer:u.c.applinks.yce">YouCam Edit</Trans>,
    link: 'https://yce.perfectcorp.com/?utm_source=PF_site&utm_medium=PF_home',
    i18nKey: 'consumer:u.c.applinks.yce',
    iosUrl: 'https://apps.apple.com/app/apple-store/id6444827065',
    cnIosUrl: '',
    androidUrl: 'https://yce.page.link/PFwebsite_yceblog',
    cnAndroidUrl: '',
    bannerIOSUrl: 'https://yce.page.link/PFwebsite_top_banner_iOS',
    bannerAndroidUrl: 'https://yce.page.link/PFwebsite_yceblog',
    appsPageBannerIOSUrl: 'https://yce.page.link/PFwebsite_top_prod_iOS',
    appsPageBannerAndroidUrl: 'https://yce.page.link/PFwebsite_yceblog',
    version: '5.62.0',
  },
  yca: {
    id: 'youcam_ai',
    bgColor: '#e53874',
    text: <Trans i18nKey="consumer:u.c.applinks.yca">YouCam AI Pro</Trans>,
    link: '/consumer/apps/yca',
    i18nKey: 'consumer:u.c.applinks.yca',
    iosUrl:
      'https://apps.apple.com/app/apple-store/id6451239387?pt=117886073&ct=PFwebsite_ycablog&mt=8',
    cnIosUrl:
      'https://apps.apple.com/app/apple-store/id6451239387?pt=117886073&ct=PFwebsite_ycablog&mt=8',
    androidUrl: '',
    cnAndroidUrl: '',
    bannerIOSUrl:
      'https://apps.apple.com/app/apple-store/id6451239387?pt=117886073&ct=PFwebsite_ycablog&mt=8',
    bannerAndroidUrl: '',
    appsPageBannerIOSUrl:
      'https://apps.apple.com/app/apple-store/id6451239387?pt=117886073&ct=PFwebsite_ycablog&mt=8',
    appsPageBannerAndroidUrl: '',
    version: '5.62.0',
  },
  ycoe: {
    isWeb: true,
    id: 'youcam_online_editor',
    bgColor: '#212529',
    text: (
      <Trans i18nKey="consumer:u.c.applinks.color.ycoe">
        <div style={{color: '#212529'}}>
          YouCam <span style={{color: '#03ade2'}}>Online Editor</span>
        </div>
      </Trans>
    ),
    link: 'https://yce.perfectcorp.com/',
    i18nKey: 'consumer:u.c.applinks.ycoe',
    iosUrl: 'https://yce.perfectcorp.com/',
    cnIosUrl: 'https://yce.perfectcorp.com/',
    androidUrl: 'https://yce.perfectcorp.com/',
    cnAndroidUrl: 'https://yce.perfectcorp.com/',
    bannerIOSUrl: 'https://yce.perfectcorp.com/',
    bannerAndroidUrl: 'https://yce.perfectcorp.com/',
    appsPageBannerIOSUrl: 'https://yce.perfectcorp.com/',
    appsPageBannerAndroidUrl: 'https://yce.perfectcorp.com/',
    version: '5.62.0',
  },
  // y4b: {
  //   id: 'menu.consumer.y4b',
  //   bgColor: '#f44b97',
  //   text: (
  //     <Trans i18nKey="consumer:u.c.applinks.y4b">YouCam for Business</Trans>
  //   ),
  //   link: '/consumer/apps/y4b',
  //   iosUrl: 'https://itunes.apple.com/us/app/id1450244365',
  //   androidUrl:
  //     'https://play.google.com/store/apps/details?id=com.perfectcorp.ymkbusiness',
  // },
};

export default links;
