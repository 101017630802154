import React, {useEffect, useState} from 'react';
import {Route} from 'react-router';
import {useTranslation} from 'react-i18next';
import Footer4BusinessAsync from './footer4business/async';
import useScrollToTop from '../hooks/useScrollToTop';
import LazyLoad, {forceVisible} from 'react-lazyload';
import '../../assets/styles/languages/languagefontfamily.css';
import classnames from 'classnames';
import webUtils from '../../utils/webUtils';
import FadeTransition from '../transition/fade';
import GoContactButton from '../gotocontactbtn';
import GoDemoButton from '../godemobtn';
import _indexOf from 'lodash/indexOf';
import beautyTechForumDao from '../../dao/beautyTechForumDao';
import Footer from './footer/async';
import CookieStatement from './header/CookieStatement';
import storeUtils from '../../utils/storeUtils';
import _get from 'lodash/get';
import useForceCheck from '../hooks/useForceCheck';
import FloatingBtns from '../floatingbtns';
import _isEmpty from 'lodash/isEmpty';

export default ({
  component: Component,
  isEnterprise = false,
  breadcrumb = [],
  contactsalesbutton = true,
  demostorebutton = true,
  isGbtf = false,
  ...rest
}) => {
  useForceCheck();
  forceVisible();
  useScrollToTop();
  const {i18n} = useTranslation();
  const isInvestors = webUtils.isInvestors();
  const isGcse = webUtils.isGcse();
  const langParam =
    i18n.language === 'en' || !i18n.language
      ? ''
      : `/${i18n.language.replace('_', '-').toLowerCase()}`;
  const isSdkPrivacy =
    _indexOf(
      [`${langParam}/business/SDK/privacy`],
      window.location.pathname
    ) !== -1;
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [hideStatement, setHideStatement] = useState(true);
  const [sessions, setSessions] = useState({});
  const [course, setCourse] = useState(null);
  const paramLang = rest.computedMatch.params.lang;
  const languageType = paramLang ? paramLang : 'enu';
  const courseKey = rest.computedMatch.params.courseKey;
  useEffect(() => {
    if (isGbtf) {
      const params = {languageType, courseType: 'VIRTUAL_EVENT'};
      beautyTechForumDao.listCourse({courseType: 'VIRTUAL_EVENT'}).then(res => {
        const currentGbtf = res.filter(
          rrr => rrr.isLatest && rrr.languageType === languageType
        )[0];
        const course =
          courseKey && !_isEmpty(res.filter(rrr => rrr.courseKey === courseKey))
            ? res.filter(rrr => rrr.courseKey === courseKey)[0]
            : currentGbtf;
        beautyTechForumDao
          .listSessionsByCourseId({...params, courseId: course.id})
          .then(result => {
            setSessions(result);
          });
        setCourse(course);
        if (_get(course, 'pageType') === 'EVENT_PAGE') {
          setIsEvent(true);
          if (window.navigator.userAgent === 'ReactSnap') {
            return;
          }
          setHideStatement(storeUtils.getCookieStatement());
        } else {
          setIsEvent(false);
        }
      });
    }
  }, [paramLang, window.location.href]);

  const noHeaderContent = matchProps => {
    return (
      <Component
        {...matchProps}
        breadcrumb={breadcrumb}
        sessions={sessions}
        course={course}
      />
    );
  };
  if (isEvent) {
    return (
      <Route
        {...rest}
        render={matchProps => {
          return (
            <div>
              <GoContactButton contactsalesbutton={contactsalesbutton} />
              {!isInvestors && !isGcse && demostorebutton && <GoDemoButton />}
              <div
                className={classnames({
                  'pf-language': true,
                  'pf-language-ja': i18n.language === 'ja',
                })}
              >
                {noHeaderContent(matchProps)}
                <CookieStatement
                  hideStatement={!!hideStatement}
                  setHideStatement={setHideStatement}
                />
                <LazyLoad
                  height={500}
                  once={true}
                  unmountIfInvisible={true}
                  throttle={100}
                >
                  <Footer isGbtfEvent={true} pageReady={true} />
                </LazyLoad>
              </div>
            </div>
          );
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <FadeTransition>
            {/*{webUtils.isCnDomain() && <FloatingBtns />}*/}
            {!isSdkPrivacy && (
              <GoContactButton contactsalesbutton={contactsalesbutton} />
            )}
            {!isInvestors && !isSdkPrivacy && !isGcse && demostorebutton && (
              <GoDemoButton />
            )}
            <div
              className={classnames({
                'pf-language': true,
                'pf-language-ja': i18n.language === 'ja',
              })}
            >
              <div style={{backgroundColor: '#fff'}}>
                <Component
                  {...matchProps}
                  breadcrumb={breadcrumb}
                  sessions={sessions}
                  course={course}
                  isFooterVisible={isFooterVisible}
                />
              </div>
              {!webUtils.isMobile() ? (
                <Footer4BusinessAsync
                  setShow={setIsFooterVisible}
                  pageReady={true}
                />
              ) : (
                <LazyLoad
                  height={500}
                  once={true}
                  unmountIfInvisible={true}
                  throttle={100}
                >
                  {isSdkPrivacy ? (
                    <></>
                  ) : (
                    <Footer4BusinessAsync
                      setShow={setIsFooterVisible}
                      pageReady={true}
                    />
                  )}
                </LazyLoad>
              )}
            </div>
          </FadeTransition>
        );
      }}
    />
  );
};
