import React from 'react';
import Home from './home/async';
import Post from './post/async';
import Author from './author/async';
import Search from './search/async';
import Category from './category/async';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/blog/author/:name"
    exact
    path="/:type(business)/blog/author/:name"
    component={Author}
  />,
  <Route
    key="/business/blog/post"
    exact
    path="/:type(business)/blog/post"
    component={Post}
  />,
  <Route
    key="/business/blog/author"
    exact
    path="/:type(business)/blog/author"
    component={Author}
  />,
  <Route
    key="/business/blog/search"
    exact
    path="/:type(business)/blog/search/:keywords"
    component={Search}
  />,
  <Route
    key="/business/blog/category/:attr"
    exact
    path="/:type(business)/blog/category/:attr"
    component={Search}
  />,
  <Route
    key="/business/blog/:category/:title"
    exact
    path="/:type(business)/blog/:category/:title"
    component={Post}
  />,
  <Route
    key="/business/blog/:category"
    exact
    path="/:type(business|consumer)/blog/:category(success-story|makeup|ai-skincare|hair|general|on-demand-beauty-tech|retail|commerce|photo-editing|video-editing|selfie-editing|nail|online-photo-editing|generative-AI)"
    component={Category}
  />,
  <Route
    key="/business/blog"
    exact
    path="/:type(business)/blog/:page?"
    component={Home}
  />,
];
