import React from 'react';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import GlobalBeautyTechForum from './index';

export default [
  <DefaultBusinessLayout
    key="/business/global-beauty-tech-forum"
    exact
    path="/business/global-beauty-tech-forum/:lang(|fra|enu|jpn)?"
    component={props => <GlobalBeautyTechForum {...props} />}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'GBTF',
        item: '/business/global-beauty-tech-forum',
      },
    ]}
    isGbtf={true}
  />,
  <DefaultBusinessLayout
    key="/business/global-beauty-tech-forum"
    exact
    path="/business/global-beauty-tech-forum/:courseKey"
    component={props => <GlobalBeautyTechForum {...props} />}
    isGbtf={true}
  />,
];
