import fetch from 'isomorphic-fetch';
import storeUtils from '../utils/storeUtils';
import affiliateUtils from '../utils/affiliateUtils';
import gtmUtils from '../utils/gtmUtils';
import webUtils from '../utils/webUtils';
import i18n from 'i18next';
import utmUtils from '../utils/utmUtils';
import _get from 'lodash/get';
import cookieUtils from '../utils/cookieUtils';

export const cache = {accountSummary: null, isFirstLoaded: false};

const dao = {
  loadAuth: async () => {
    if (!storeUtils.getIsAttemptingToSignIn() && !webUtils.isInYmkApp()) {
      return Promise.reject('loadAuth-fail');
    }
    const hostname = window && window.location && window.location.hostname;
    const api =
      hostname === 'www.perfectcorp.cn'
        ? `https://www.perfectcorp.com/smb-frontend/auth/auth.action`
        : `/smb-frontend/auth/auth.action`;
    const response = await fetch(api, {
      method: 'GET',
      credentials: 'same-origin',
    });
    if (response.ok) return await response.json();
    return Promise.reject('loadAuth-fail');
  },
  signIn: async (
    email,
    password,
    recaptcha = '',
    customerType,
    next,
    functionType,
    domain
  ) => {
    storeUtils.setIsAttemptingToSignIn(true);
    const response = await fetch(`/smb-frontend/auth/sign-in.action`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        recaptcha,
        customerType,
        next,
        functionType,
        responseType: 'json',
        domain,
      }),
    });
    if (response.ok) return await response.json();
    const error = await response.json();
    return Promise.reject(error);
  },
  signInWithToken: async (token, functionType, recaptcha, next) => {
    storeUtils.setIsAttemptingToSignIn(true);
    const response = await fetch(`/smb-frontend/auth/sign-in.action`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        recaptcha,
        customerType: 'SMB',
        functionType,
        responseType: 'json',
        next,
      }),
    });
    if (response.ok) return await response.json();
    const error = await response.json();
    return Promise.reject(error);
  },
  signOut: async () => {
    const response = await fetch(`/smb-frontend/auth/sign-out.action`, {
      method: 'GET',
      credentials: 'same-origin',
    });
    if (response.ok) return response;
    return Promise.reject('signOut-fail');
  },
  checkEmail: async email => {
    const response = await fetch(`/smb-frontend/auth/check-email.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    });
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  checkWhiteList: async email => {
    const response = await fetch(
      `/smb-frontend/subscription/check-whitelist.action
`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  checkBusinessName: async businessName => {
    const response = await fetch(
      `/smb-frontend/auth/check-business-name.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({businessName}),
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  checkTrial: async param => {
    try {
      const response = await fetch(
        `/smb-frontend/subscription/check-trial.action`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(param),
        }
      );
      if (response.ok) return await response.json();
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    } catch (err) {
      return Promise.reject();
    }
  },
  createTrial: async accountData => {
    const sourceUtm = utmUtils.getSupportSourceUtm();
    const functionType = accountData.customer.functionType;
    const response = await fetch(
      `/smb-frontend/subscription/create-trial.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...accountData, sourceUtm, responseType: 'json'}),
      }
    );
    if (response.ok) {
      storeUtils.setIsAttemptingToSignIn(true);
      const json = await response.json();
      const approvalStatus = _get(json, 'approvalStatus');
      const language = webUtils.getLangPath(i18n.language);
      if (
        cookieUtils.getCookie(`${functionType}_isBypassApproval`) === 'true'
      ) {
        return (window.location.href = json['redirectUrl']);
      }
      if (approvalStatus === 'SUBMITTED') {
        return (window.location.href = `${language}/business/user/signup-confirmed?functionType=${functionType}`);
      }
      const url = `${language}/business/user/free-trial/success?functionType=${functionType}`;
      gtmUtils.sendVirtualPageView({
        type: 'FREE_TRIAL',
        url: url,
        title: 'start free trial',
      });
      gtmUtils.sendMultilingualVirtualPageView(
        {
          url: url,
          title: 'start free trial',
        },
        webUtils.determineLanguage(i18n.language)
      );
      console.log({json});
      affiliateUtils
        .triggerMemberRegisterAffiliate(json['customerId'])
        .then(r => {
          console.log({r});
        });
      return json;
    }
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  checkSubscription: async param => {
    const response = await fetch(
      `/smb-frontend/subscription/check-subscription.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(param),
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  createSubscription: async accountData => {
    const response = await fetch(
      `/smb-frontend/subscription/create-subscription.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...accountData, responseType: 'json'}),
      }
    );
    if (response.ok) {
      storeUtils.setIsAttemptingToSignIn(true);
      const json = await response.json();
      window.location.href = json['redirectUrl'];
      return json;
    }
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  checkPartner: async () => {
    const response = await fetch(`/smb-frontend/partner/check-partner.action`, {
      method: 'GET',
      credentials: 'same-origin',
    });
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  createPartner: async accountData => {
    const response = await fetch(
      `/smb-frontend/partner/create-partner.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...accountData, responseType: 'json'}),
      }
    );
    if (response.ok) {
      storeUtils.setIsAttemptingToSignIn(true);
      return await response.json();
    }
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  verifyEmail: async code => {
    const response = await fetch(`/smb-frontend/account/verify-email.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({code}),
    });
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  forgotPassword: async email => {
    const response = await fetch(
      `/smb-frontend/account/forgot-password.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      }
    );
    if (response.ok) return await response.json();
    try {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        message: await response.json(),
      });
    } catch (err) {
      return Promise.reject(err);
    }
  },
  resetPassword: async (code, password) => {
    const response = await fetch(
      `/smb-frontend/account/reset-password.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({code, password}),
      }
    );
    if (response.ok) return await response.json();
    try {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        message: await response.json(),
      });
    } catch (err) {
      return Promise.reject(err);
    }
  },
  setupPassword: async (code, password) => {
    const response = await fetch(
      `/smb-frontend/account/setup-password.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({code, password}),
      }
    );
    if (response.ok) return await response.json();
    try {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        message: await response.json(),
      });
    } catch (err) {
      return Promise.reject(err);
    }
  },
  checkPasswordExpire: async (code, passwordType) => {
    const response = await fetch(
      `/smb-frontend/account/check-password-code-expire.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({code, passwordType}),
      }
    );
    if (response.ok) return await response.json();
    try {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        message: await response.json(),
      });
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default dao;
