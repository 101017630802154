import React from "react";
import DefaultBusinessLayout from "../../../../components/layout/DefaultBusinessLayout";
import AsyncPage from "./async";

export default [
    <DefaultBusinessLayout
        key="/business/partners"
        exact
        path="/business/partners"
        component={AsyncPage}
        isEnterprise={true}
        breadcrumb={[
            {
                position: 1,
                name: 'Home',
                item: '/',
            },
            {
                position: 2,
                name: 'Business',
                item: '/business',
            },
            {
                position: 3,
                name: 'Partners',
                item: '/business/partners',
            },
        ]}
        contactsalesbutton={false}
    />
];
