import React from 'react';
import AsyncPage from './async';
import BlankLayout from '../../../../components/layout/BlankLayout';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import AsyncThankPage from './thankyou/async';

export default [
  <DefaultBusinessLayout
    key="/business/contact-sales/:thankyou"
    exact
    path="/business/contact-sales/:thankyou(thank-you)"
    component={AsyncThankPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Thank you',
        item: '/business/contact-sales/thankyou',
      },
    ]}
    contactsalesbutton={false}
  />,
  <BlankLayout
    key="/business/contact-sales"
    exact
    path="/business/contact-sales"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Contact Sales',
        item: '/business/contact-sales',
      },
    ]}
    contactsalesbutton={false}
  />,
];
