import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import FooterAsync from './footer/async';
import menus from './footer-menus-4-business';
import webUtils from '../../utils/webUtils';
import blogUtils from '../../utils/blogUtils';
import LazyLoad, {forceVisible} from 'react-lazyload';
import '../../assets/styles/languages/languagefontfamily.css';
import btfUtils from '../../utils/btfUtils';
import classnames from 'classnames';
import FadeTransition from '../transition/fade';
import GoContactButton from '../gotocontactbtn';
import GoDemoButton from '../godemobtn';
import GotoFreeTrial from '../gotofreetrial';
import OnlineServiceButton from '../onlineservicebutton';
import useForceCheck from '../hooks/useForceCheck';
import FloatingBtns from '../floatingbtns';

export default ({
  children,
  contactsalesbutton = true,
  showFreeTrialButton = false,
  showDemoStoreButton = true,
  showOnlineServiceButton = false,
  buttons = [],
  setProductType = () => {},
  productType,
  show,
  setIsSliding = () => {},
}) => {
  useForceCheck();
  forceVisible();
  const {i18n} = useTranslation();
  const isCHS = webUtils.isChs(i18n.language);
  const isBlogEnabled = blogUtils.isEnabled();
  const isBtfEnabled = btfUtils.isBtfEnabled();
  const isBeautyTrendEnabled = i18n.language !== 'zh_CN';
  const isEducationEnabled = i18n.language === 'en';
  const filterMenu = menuOptions => {
    return menuOptions.map(m => {
      if (m.id === 'menu.business.services' && isCHS) {
        return {
          ...m,
          items: m.items.filter(i => i.id !== 'menu.business.livestreaming'),
        };
      }
      if (m.id === 'menu.business.resources') {
        return {
          ...m,
          items: m.items.filter(i => {
            switch (i.id) {
              case 'menu.business.blog':
                return isBlogEnabled;
              case 'menu.business.btf':
                return isBtfEnabled;
              case 'menu.business.beautyTrend':
                return isBeautyTrendEnabled;
              case 'menu.business.btfv.2021':
                return i18n.language === 'fr';
              case 'menu.business.btfv':
                return i18n.language === 'en';
              case 'menu.business.education':
                return isEducationEnabled;
              default:
                return true;
            }
          }),
        };
      }
      return m;
    });
  };

  useEffect(() => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollTop > 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <FadeTransition
      buttons={buttons}
      setProductType={setProductType}
      productType={productType}
      show={show}
      setIsSliding={setIsSliding}
    >
      {/*{webUtils.isCnDomain() && <FloatingBtns />}*/}
      <GoContactButton contactsalesbutton={contactsalesbutton} />
      {showDemoStoreButton && <GoDemoButton />}
      {showOnlineServiceButton && <OnlineServiceButton />}
      {showFreeTrialButton && <GotoFreeTrial />}
      <div
        className={classnames({
          'pf-language': true,
          'pf-language-ja': i18n.language === 'ja',
        })}
      >
        <div
          style={{
            backgroundColor: '#fff',
            minHeight: '100vh',
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
          id={'prerenderlayout'}
        >
          {children}
        </div>
        <LazyLoad
          height={500}
          once={true}
          unmountIfInvisible={true}
          throttle={100}
        >
          <FooterAsync
            isBusiness={true}
            menus={filterMenu(menus)}
            pageReady={true}
          />
        </LazyLoad>
      </div>
    </FadeTransition>
  );
};
