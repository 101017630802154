import _flattenDeep from 'lodash/flattenDeep';
import aifacialrecognition from './aifacialrecognition/routes';
import aiproductrecommendation from './aiproductrecommendation/routes';
import aishadefinder from './aishadefinder/routes';
import arskindiagnostic from './arskindiagnostic/routes';
import ba1on1 from './ba1on1/routes';
import instorebarcode from './instorebarcode/routes';
import liveartraining from './liveartraining/routes';
import livehaircolor from './livehaircolor/routes';
import livestreaming from './livestreaming/routes';
import virtualaccessories from './virtualaccessories/routes';
import virtualmakeup from './virtualmakeup/routes';
import ar3deyewear from './ar3deyewear/routes';
import youcamtutorial from './youcamtutorial/routes';
import aibearddye from './aibearddye/routes';
import aifaceanalyzer from './aifaceanalyzer/routes';
import ainailcolor from './ainailcolor/routes';
import arwatch from './arwatch/routes';
import arring from './arring/routes';
import arbracelet from './arbracelet/routes';
import virtualearrings from './virtualearrings/routes';
import facereshape from './facereshape/routes';
import aipersonaility from './aipersonaility/routes';
import background from './background/routes';
import hairstyles from './hairstyles/routes';
import skinemulation from './skinemulation/routes';
import arnecklace from './necklace/routes';
import threedview from './threedview/routes';
import hairtype from './hairtype/routes';
import skinanalysisvalidator from './skinanalysisvalidator/routes';

export default [
  // aifacialrecognition,
  aiproductrecommendation,
  aishadefinder,
  arskindiagnostic,
  ba1on1,
  instorebarcode,
  liveartraining,
  livehaircolor,
  livestreaming,
  virtualaccessories,
  virtualmakeup,
  ar3deyewear,
  youcamtutorial,
  aibearddye,
  aifaceanalyzer,
  ainailcolor,
  arwatch,
  arring,
  arbracelet,
  virtualearrings,
  facereshape,
  aipersonaility,
  background,
  hairstyles,
  skinemulation,
  arnecklace,
  threedview,
  hairtype,
  skinanalysisvalidator,
];
