import React, {useEffect, useState} from 'react';
import Seo from '../../../../components/seo/index';
import {useTranslation} from 'react-i18next';
import useWindowWidth from '../../../../components/hooks/useWindowWidth';
import _isEmpty from 'lodash/isEmpty';
import PrerenderLayout from '../../../../components/layout/PrerenderLayout';
import LoadingPage from '../../../../components/loadingpage';
import Strapi from '../../../../components/strapi';
import GetStrapiContent from '../../../../components/strapi/getstrapicontent';
import Pricing from '../../plan/pricing/async';
import webUtils from '../../../../utils/webUtils';

export default function OnlineService({match: {params}, location}) {
  const getSeo = () => {
    if (webUtils.getOnlineServiceLink().functionType === 'SKINCARE_PRO') {
      return (
        <Seo
          key="seo"
          title={t(
            'seo:business.online.service.skincare.title',
            'Skincare Pro | Virtual Try-On for Beauty Brands'
          )}
          description={t(
            'seo:business.online.service.skincare.desc',
            'A Dermatologist Verified Software Replacing Commercial Skin Scanning Device'
          )}
          url="/business/solutions/online-service/skincare-pro"
          breadcrumb={[
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: '/business',
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: t(
                'seo:business.online.service.skincare.title',
                'Skincare Pro | Virtual Try-On for Beauty Brands'
              ),
              item: '/business/solutions/online-service/skincare-pro',
            },
          ]}
        />
      );
    }
    if (webUtils.getOnlineServiceLink().functionType === 'EYEWEAR') {
      return (
        <Seo
          key="seo"
          title={t(
            'seo:business.online.service.eyewear.title',
            'Skincare Pro | Virtual Try-On for Beauty Brands'
          )}
          description={t(
            'seo:business.online.service.eyewear.desc',
            'A Dermatologist Verified Software Replacing Commercial Skin Scanning Device'
          )}
          url="/business/solutions/online-service/eye-wear"
          breadcrumb={[
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: '/business',
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: t(
                'seo:business.online.service.eyewear.title',
                'Skincare Pro | Virtual Try-On for Beauty Brands'
              ),
              item: '/business/solutions/online-service/eye-wear',
            },
          ]}
        />
      );
    }

    return (
      <Seo
        key="seo"
        title={t(
          'seo:business.online.service.title',
          'Online Service | Turnkey Virtual Try-On Solution for Beauty E-commerce'
        )}
        description={t(
          'seo:business.online.service.desc',
          'No coding needed! Zero-effort integration! The easiest turnkey virtual try-on solution for beauty brands to enhance add-to-carts and customer satisfaction.'
        )}
        url={window.location.pathname}
        breadcrumb={[
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: '/business',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: t(
              'seo:business.online.service.title',
              'Online Service | Turnkey Virtual Try-On Solution for Beauty E-commerce'
            ),
            item: '/business/solutions/online-service/makeup',
          },
        ]}
      />
    );
  };
  const [pageType, setPageType] = useState(
    location.pathname.indexOf('plan') !== -1 ? 'plan' : 'feature'
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const {i18n, t} = useTranslation();
  const width = useWindowWidth();
  const {strapiContent} = GetStrapiContent(
    webUtils.getOnlineServiceLink().strapiEnum,
    setIsLoaded,
    location,
    true
  );
  const content =
    !_isEmpty(strapiContent) &&
    (pageType === 'feature' ? strapiContent : strapiContent.slice(0, 1));

  useEffect(() => {
    if (location.pathname.indexOf('/plan') !== -1) {
      setPageType('plan');
    } else {
      setPageType('feature');
    }
  }, [location.pathname]);

  if (!isLoaded || _isEmpty(strapiContent))
    return (
      <PrerenderLayout>
        <LoadingPage />
      </PrerenderLayout>
    );

  return (
    <PrerenderLayout
      contactsalesbutton={false}
      showDemoStoreButton={false}
      showOnlineServiceButton={true}
      showFreeTrialButton={true}
      functionType={webUtils.getOnlineServiceLink().functionType}
    >
      {getSeo()}
      {content.map((s, i) => (
        <div key={`online-service-strapi-sec${i}`}>
          <Strapi
            content={s}
            width={width}
            productPage={'online-service'}
            i18n={i18n}
            pageType={pageType}
            setPageType={setPageType}
          />
        </div>
      ))}
      {pageType === 'plan' && isLoaded && !_isEmpty(strapiContent) && (
        <Pricing location={location} match={{params: params}} />
      )}
    </PrerenderLayout>
  );
}
