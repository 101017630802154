import React from 'react';
import AsyncPage from './async';
import {Redirect} from 'react-router';
import {Route} from 'react-router-dom';

export default [
  <Redirect
    key="/business/contact-us"
    exact
    from="/business/contact-us"
    to="/business/contact-us/sales"
  />,
  <Route
    key="/business/contact-us"
    exact
    path="/business/contact-us/:type(sales|media)?"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'contact-us',
        item: '/business/contact-us',
      },
    ]}
    contactsalesbutton={false}
    demostorebutton={false}
  />,
];
