import React, {useContext, useEffect, useState} from 'react';
import authDao from '../../../dao/authDao';
import webUtils from '../../../utils/webUtils';
import PardotForm from '../../../pages/business/misc/contactus/PardotForm';
import PinkButton from '../pinkbutton';
import {Trans, useTranslation} from 'react-i18next';
import userUtils from '../../../utils/userUtils';
import useWindowWidth from '../../hooks/useWindowWidth';
import contactUs from '../../../utils/config/contactus';
import langCode from '../../../utils/langCode';
import {AuthContext} from '../../auth';

export default function FreeTrialButton({gtm, style = {}, bgColor, className}) {
  const functionType = webUtils.getOnlineServiceLink().functionType;
  const solutionType = webUtils.getOnlineServiceLink().solutionType;
  const {accountSummary} = useContext(AuthContext);
  const {i18n} = useTranslation();
  const affiliationDays = userUtils.getAffiliationDays(functionType);
  const [sendPardot, setSendPardot] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const langParam = webUtils.getLangPath(i18n.language);

  return (
    <>
      <PardotForm
        iframe={`https://pardot.${webUtils.getPardotDomain()}.com/l/925403/2021-06-07/5fzn`}
        action={`https://pardot.${webUtils.getPardotDomain()}.com/l/925403/2021-06-07/5fzl?success_location=${webUtils.getSMBEnvReactAppUrl()}`}
        sendPardot={sendPardot}
        input={[
          {value: accountSummary.account.email, name: 'email'},
          {value: accountSummary.customer.customerName, name: 'businessName'},
          {value: accountSummary.customer.phone, name: 'phone'},
          {value: accountSummary.account.firstName, name: 'firstName'},
          {value: accountSummary.account.lastName, name: 'lastName'},
          {
            value: contactUs.convertEmployee(
              accountSummary.customer.numberOfEmployees
            ),
            name: 'numberOfEmployees',
          },
          {value: accountSummary.customer, name: 'country'},
          {value: accountSummary.customer.websiteUrl, name: 'websiteUrl'},
          {value: 'Free Trial', name: 'Subsource'},
          {value: 'Opt-in from Website', name: 'Source'},
          {
            value: webUtils.getTagsPfByFunctionType(),
            name: 'Tags_PF',
          },
          {
            value: accountSummary.account.isNotificationEnabled ? '0' : '1',
            name: 'optout',
          },
        ]}
      />
      <PinkButton
        className={className}
        gtm={gtm || ''}
        text={
          <div>
            {isLoading && <div className="spinner-border spinner-border-sm" />}
            <div style={{visibility: isLoading ? 'hidden' : 'visible'}}>
              <Trans i18nKey="business:p.b.p.pricing.smb.compare.table2.try.free30">
                {{affiliationDays}} Days Free Trial
              </Trans>
            </div>
          </div>
        }
        style={{...style}}
        square={true}
        bgColor={bgColor || 'blue-purple-linear'}
        func={() => {
          setIsLoading(true);
          authDao
            .checkTrial({functionType, solutionType})
            .then(json => {
              if (json.status === 'existed')
                window.location.href = webUtils.getSMBEnvReactAppUrl();
              else if (json.status === 'expired')
                window.location.href = langParam + '/business/plan';
              else if (json.status === 'form')
                window.location = `${langParam}${webUtils.goToFreeTrial(
                  functionType,
                  solutionType
                )}`;
              else if (json.status === 'ok') {
                authDao
                  .createTrial({
                    account: {
                      languageType: langCode.convertLanguageCode(
                        i18n.language || 'en'
                      ),
                      email: accountSummary.account.email,
                      firstName: accountSummary.account.firstName,
                      lastName: accountSummary.account.lastName,
                      isNotificationEnabled:
                        accountSummary.account.isNotificationEnabled,
                    },
                    solutionType,
                    customer: {
                      customerName: accountSummary.customer.customerName,
                      phone: accountSummary.customer.phone,
                      numberOfEmployees:
                        accountSummary.customer.numberOfEmployees,
                      country: accountSummary.customer.country,
                      businessType:
                        accountSummary.customer.businessType || 'MAKEUP',
                      websiteUrl: accountSummary.customer.websiteUrl,
                      functionType: functionType,
                    },
                    subscriptionType: 'PREMIUM_TRIAL',
                    referralCode: '',
                    refUrl: window.location.href,
                    sourceUtm: {},
                    responseType: 'json',
                  })
                  .then(() => {
                    setTimeout(() => setSendPardot(true), 0);
                    setTimeout(() => {
                      window.location.href = webUtils.getSMBEnvReactAppUrl();
                    }, 200);
                  });
              }
            })

            .catch(() => {});
        }}
      />
    </>
  );
}
