import React from 'react';
import Async from './async';
import DefaultNFTLayout from '../../../../components/layout/DefaultNFTLayout';
import {Redirect} from 'react-router';

export default [
  <DefaultNFTLayout
    key="/nft"
    exact
    path="/nft"
    component={Async}
    footer={true}
    menu={true}
  />,
];
