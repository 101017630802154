import React, {useEffect, useState} from 'react';
import {Route} from 'react-router';
import classnames from 'classnames';
import {Trans, useTranslation} from 'react-i18next';
import NFTHeader from './header/nftheader';
import NFTFooter from './footer/nftfooter';
import CookieStatement from './header/CookieStatement';
import storeUtils from '../../utils/storeUtils';

export default ({
  component: Component,
  menu = false,
  footer = false,
  terms = false,
  ...rest
}) => {
  const [activeText, setActiveText] = useState('');
  const params = new URLSearchParams(window.location.search);
  const is2b = params.get('is2b') === 'true';
  const {i18n} = useTranslation();
  const language =
    i18n.language === 'en' || !i18n.language
      ? ''
      : `/${i18n.language.replace('_', '-').toLowerCase()}`;
  const item = [
    {
      show: '2b',
      text: (
        <Trans i18nKey="business:c.l.nft.header.how.it.works">
          How it works?
        </Trans>
      ),
      hashlink: true,
      link: `${language}/nft?is2b=${is2b}#howitwork`,
      activeText: 'howitwork',
    },
    {
      show: 'all',
      text: (
        <Trans i18nKey="business:c.l.nft.header.nft.collection">
          NFT Collection
        </Trans>
      ),
      hashlink: true,
      link: `${language}/nft?is2b=${is2b}#nftcollection`,
      activeText: 'nftcollection',
    },
    {
      show: 'all',
      text: <Trans i18nKey="business:c.l.nft.header.faq">FAQ</Trans>,
      hashlink: true,
      link: `${language}/nft?is2b=${is2b}#faq`,
      activeText: 'faq',
    },
    {
      show: '2b',
      text: (
        <Trans i18nKey="business:c.l.nft.header.contact.us">Contact Us</Trans>
      ),
      hashlink: false,
      link: `/business/contact-us/sales`,
      activeText: 'contactus',
    },
  ];
  const [hideStatement, setHideStatement] = useState(true);
  useEffect(() => {
    if (window.navigator.userAgent === 'ReactSnap') {
      return;
    }
    setHideStatement(storeUtils.getCookieStatement());
  }, []);

  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <div
            className={classnames({
              'pf-language': true,
              'pf-language-ja': i18n.language === 'ja',
            })}
          >
            <NFTHeader
              activeText={activeText}
              menu={menu}
              is2b={is2b}
              item={is2b ? item : item.filter(s => s.show && s.show !== '2b')}
              BannerIcon={{
                text: <Trans i18nKey="business:c.l.nft.header.nft">NFT</Trans>,
                func: () => {
                  if (terms) window.open(`${language}/nft?is2b=${is2b}`);
                },
                style: {
                  cursor: terms ? 'pointer' : 'context-menu',
                },
              }}
            />
            <Component {...matchProps} setActiveText={setActiveText} />
            <CookieStatement
              hideStatement={!!hideStatement}
              setHideStatement={setHideStatement}
            />
            {footer && (
              <NFTFooter terms={terms} is2b={is2b} language={language} />
            )}
          </div>
        );
      }}
    />
  );
};
