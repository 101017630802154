import i18n from 'i18next';
import _get from 'lodash/get';
import _join from 'lodash/join';
import _upperFirst from 'lodash/upperFirst';
import _camelCase from 'lodash/camelCase';
import webUtils from './webUtils';
import filterMap from './config/blogFilters';
import _flatten from 'lodash/flatten';

const utils = {
  isEnabled: () =>
    ['en', 'zh_TW', 'zh_CN', 'ja', 'fr', 'ko', 'de', 'es', 'it'].indexOf(
      webUtils.determineLanguage(i18n.language)
    ) !== -1,
  convertLang: lang => {
    const langMap = {
      zh_CN: 'chs',
      'zh-cn': 'chs',
      'zh-tw': 'cht',
      zh_TW: 'cht',
      en: 'enu',
      fr: 'fra',
      ja: 'jpn',
      ko: 'kor',
      de: 'deu',
      es: 'esp',
      it: 'ita',
      pt: 'ptb',
    };
    return langMap[lang] || 'enu';
  },
  convertLanguage: lang => {
    const langMap = {
      chs: 'zh-cn',
      cht: 'zh-tw',
      enu: 'en',
      fra: 'fr',
      jpn: 'ja',
      kor: 'ko',
      deu: 'de',
      esp: 'es',
      ita: 'it',
      ptb: 'pt',
    };
    return langMap[lang];
  },
  getAuthorLink: author => {
    const type = webUtils.isBusiness() ? 'business' : 'consumer';
    return _get(author, 'authorHandle')
      ? `/${type}/blog/author/${author.authorHandle}`
      : `/${type}/blog/author?authorId=${_get(author, 'id')}`;
  },
  getPostLink: post => {
    const type = webUtils.isBusiness() ? 'business' : 'consumer';
    return _get(post, 'postHandle')
      ? `/${type}/blog/${post.postHandle}`
      : `/${type}/blog/post?postId=${_get(post, 'id')}`;
  },
  getNewsLink: post => {
    const type = webUtils.isBusiness() ? 'business' : 'consumer';
    const language = webUtils
      .determineLanguage(i18n.language, webUtils.isBusiness())
      .replace('_', '-')
      .toLowerCase();
    return `${
      language === 'en' ? '' : `/${language}`
    }/${type}/news/detail/${_get(post, 'id')}`;
  },
  joinKeywordsToHashtags: (postKeywords = [], separator = ',') => {
    const hashtags = postKeywords.map(item =>
      utils.toHashtagFormat(_get(item, 'keyword.title'))
    );
    return _join(hashtags, separator);
  },
  getTransformedMonth: (language, dayObj) => {
    const monthMap = {
      zh_cn: dayObj.format('M'),
      zh_tw: dayObj.format('M'),
      'zh-cn': dayObj.format('M'),
      'zh-tw': dayObj.format('M'),
      en: dayObj.format('MMM'),
      ja: dayObj.format('M'),
      fr: webUtils.convertToFrenchMonth(dayObj.month()),
      de: dayObj.format('MMM'),
      es: dayObj.format('MMM'),
      ko: dayObj.format('MMM'),
      it: dayObj.format('MMM'),
      pt: dayObj.format('MMM'),
    };
    return monthMap[language];
  },
  toHashtagFormat(string = '') {
    return _upperFirst(_camelCase(string));
  },
  getInitialFilterValue: () => ({
    FOCUS: webUtils.isBusiness() ? 'BF_BUSINESS_FOCUS' : 'CF_CONSUMER_FOCUS',
    BUSINESS_SERVICES: [],
    BUSINESS_TOPICS: [],
    CONSUMER_APPS: [],
    CONSUMER_TOPICS: [],
  }),
  flattenedFilter: _flatten(Object.values(filterMap)).reduce((o, f) => {
    o = {...o, ...f};
    return o;
  }, {}),
  importKlaviyo: () => {
    if (_get(window, 'navigator.userAgent') === 'ReactSnap') return;
    const s = document.createElement('script');
    s.setAttribute('type', 'text/javascript');
    s.setAttribute(
      'src',
      `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=WTaNvt`
    );
    document.head.append(s);
  },
  klaviyoSubscribeList: async ({
    email,
    country,
    language,
    firstName,
    lastName,
  }) => {
    const options = {
      method: 'POST',
      headers: {
        revision: '2023-06-15',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            list_id: 'QQXh6T',
            email,
            properties: {
              Country: country,
              'Website Language': language,
              'First Name': firstName,
              'Last Name': lastName,
              'Email Address': email,
            },
          },
        },
      }),
    };
    await fetch(
      `https://a.klaviyo.com/client/subscriptions/?company_id=WTaNvt`,
      options
    )
      .then(response => {
        console.log({response});
      })
      .catch(err => {
        console.log({err});
      });
  },
};

export default utils;
