import React from 'react';
import {Trans} from 'react-i18next';

const links = {
  photoEnhance: {
    id: 'ai_photo_enhance',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.photo.enhance">
        AI Photo Enhance
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'photo-enhance',
  },
  photoColorize: {
    id: 'ai_photo_colorize',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.photo.colorize">
        AI Photo Colorize
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'colorize',
  },
  objectRemoval: {
    id: 'ai_object_removal',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.object.removal">
        AI Object Removal
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'object-removal',
  },
  removeBackground: {
    id: 'ai_removal_background',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.remove.background">
        AI Remove Background
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'remove-background',
  },
  blurBackground: {
    id: 'ai_blur_background',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.blur.background">
        AI Blur Background
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'blur-background',
  },
  changeBackground: {
    id: 'ai_change_background',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.change.background">
        AI Change Background
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'change-background',
  },
  aiavatar: {
    id: 'ai_avatar',
    text: <Trans i18nKey="consumer:u.c.leftmenu.ai.avatar">AI Avatar</Trans>,
    link: 'https://yce.perfectcorp.com/avatar',
    // hash: 'YCEW_prod_page_avatar',
    isYce: true,
    yceKeyword: 'avatar',
  },
  aihairstyles: {
    id: 'ai_hairstyles',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.hair.styles">
        AI Hairstyles
      </Trans>
    ),
    link: '/consumer/apps/ymk',
    hash: 'YMK_prod_page_hairstyles',
  },
  aifashion: {
    id: 'ai_fashion',
    text: <Trans i18nKey="consumer:u.c.leftmenu.ai.fashion">AI Fashion</Trans>,
    link: '/consumer/apps/ymk',
    hash: 'YMK_prod_page_fashion',
  },
  textimage: {
    id: 'text_image',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.text.image">Text to Image</Trans>
    ),
    link: 'https://yce.perfectcorp.com/ai-art-generator',
    isYce: true,
    yceKeyword: 'ai-art-generator',
  },
  viewalltools: {
    id: 'view_all_tools',
    text: (
      <div style={{color: '#007bff'}}>
        <Trans i18nKey="consumer:u.c.leftmenu.view.all.tools">
          View all tools
        </Trans>
      </div>
    ),
    link: 'https://yce.perfectcorp.com?utm_source=PF_site&utm_medium=PF_home  ',
    isYce: true,
    // yceKeyword: 'view-all-tools',
  },
  aihairstyle: {
    id: 'ai_hairstyle',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.hairstyle">AI Hairstyle</Trans>
    ),
    link: 'https://yce.perfectcorp.com/ai-hairstyle-generator',
    isYce: true,
    yceKeyword: 'ai-hairstyle-generator',
  },
  aiLighting: {
    id: 'ai_change_background',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.lignting">AI Lighting</Trans>
    ),
    isYce: true,
    yceKeyword: 'ai-lighting',
  },
  aiImageExtender: {
    id: 'ai_change_background',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.image.extender">
        AI Image Extender
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'ai-image-extender',
  },
  aiReplace: {
    id: 'ai_change_background',
    text: <Trans i18nKey="consumer:u.c.leftmenu.ai.replace">AI Replace</Trans>,
    isYce: true,
    yceKeyword: 'ai-replace',
  },
  makeTransfer: {
    id: 'facial-makeup-transfer-filter',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.makeup.transfer">
        Makeup Transfer
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'facial-makeup-transfer-filter',
  },
  aiHeadshot: {
    id: 'ai-headshot-generator',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.headshot">AI Headshot</Trans>
    ),
    isYce: true,
    yceKeyword: 'ai-headshot-generator',
  },
  colorCorrection: {
    id: 'color-correction',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.color.correction">
        AI Color Correction
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'color-correction',
  },
  aivideoenhance: {
    id: 'video-enhance',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.video.enhance">
        AI Video-Enhancer
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'video-enhancer-ai',
  },
  aifaceshapedetector: {
    id: 'video-enhance',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.face.shape.detector">
        AI Face Shape Detector
      </Trans>
    ),
    isYce: true,
    yceKeyword: 'face-shape-detector',
  },
  aifaceswap: {
    id: 'ai-face-swap',
    text: (
      <Trans i18nKey="consumer:u.c.leftmenu.ai.face.swap">AI Face Swap</Trans>
    ),
    isYce: true,
    yceKeyword: 'face-swap',
  },
};

export default links;
