import cookieUtils from './cookieUtils';
import _isEmpty from 'lodash/isEmpty';
import users from './config/user';
import webUtils from './webUtils';
import _isUndefined from "lodash/isUndefined";

const utils = {
  getSignInUrl: (withNext = true) =>
    '/business/user/sign-in' +
    (withNext ? '?next=' + encodeURIComponent(window.location.href) : ''),
  getAccountSettingUrl: () =>
    webUtils.getSMBEnvReactAppUrl('/general-settings/account-settings'),
  getAffiliationDays: functionType => {
    const d = cookieUtils.getCookie('affiliationDays');
    if (_isUndefined(d)) {
      return functionType === 'SKINCARE_PRO' ? 14 : users.freeTrialDays;
    }
    const tmp = JSON.parse(d);
    return !_isEmpty(tmp) && tmp[functionType]
      ? tmp[functionType]
      : functionType === 'SKINCARE_PRO'
      ? 14
      : users.freeTrialDays;
  },
};

export default utils;
