import React from 'react';
import AsyncPricing from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

const breadcrumb = [
  {
    position: 1,
    name: 'Home',
    item: '/',
  },
  {
    position: 2,
    name: 'Business',
    item: '/business',
  },
  {
    position: 3,
    name: 'Plan',
    item: '/business/plan',
  },
];
export default [
  <DefaultBusinessLayout
    key="/business/plan"
    exact
    path="/business/plan"
    component={AsyncPricing}
    breadcrumb={breadcrumb}
    contactsalesbutton={false}
    demostorebutton={false}
  />,
  <DefaultBusinessLayout
    key="/business/plan/:platform?"
    exact
    path="/business/plan/:platform(|shopify|standard)?"
    component={AsyncPricing}
    breadcrumb={breadcrumb}
  />,
];
