import privacy from './privacy/routes';
import terms from './terms/routes';
import faq from './faq/routes';
import contactus from './contactus/routes';
import info from './info/routes';
import shopifyprivacy from './shopify/privacy/routes';
import shopifyterms from './shopify/terms/routes';
import contactsales from './contactsales/routes';
import impressum from './impressum/routes';
import sdkprivacy from './sdkprivacy/routes';

export default [
  privacy,
  terms,
  info,
  faq,
  contactus,
  shopifyprivacy,
  shopifyterms,
  contactsales,
  impressum,
  sdkprivacy,
];
