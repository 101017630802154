import React from 'react';
import Async from './async';
import DefaultNFTLayout from '../../../../components/layout/DefaultNFTLayout';

export default [
  <DefaultNFTLayout
    key="/nft/terms"
    exact
    path="/nft/terms"
    component={Async}
    footer={true}
    menu={true}
    terms={true}
  />,
];
