import React, {useContext, useState, useRef} from 'react';
import {AuthContext} from '../../auth';
import {Trans, useTranslation} from 'react-i18next';
import Link from '../../../components/link';
import serviceLinks from '../../../utils/config/serviceLinks';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import './MobileBusinessMenus.css';
import webUtils from '../../../utils/webUtils';
import {fixedData} from '../subheader/Resources';
import pathUtils from '../../../utils/pathUtils';
import AgileFaceText from '../../../pages/business/technologies/agileface/RegisteredName';
import AgileHandText from '../../../pages/business/technologies/agilehand/component/registeredname';
import GoogleSearch from '../googlesearch';
import qr from 'query-string';
import DemoStoreCard from './component/demostorecard';
import SmbMenus from '../subheader/smbMenus';
import headerUtils from '../../../utils/headerUtils';
import _isEmpty from 'lodash/isEmpty';

const ArrowIcon = ({isShow, color, style = {}}) => {
  if (color === 'grey' && !isShow) {
    return (
      <div
        className=" pf-header-mobile-business-menus__arrow grey"
        style={style}
      />
    );
  }
  return (
    <div
      className={classnames({
        'pf-header-mobile-business-menus__arrow': true,
        up: isShow,
        down: !isShow,
      })}
      style={style}
    />
  );
};
let SHOW_ENTERPRISE = false;
let SHOW_BTFV = false;
let SHOW_RESOURCE = false;
let SHOW_TECHNOLOGY = false;
let SHOW_SMB = false;
export default function MobileBusinessMenus({setPopupMenu}) {
  const {i18n} = useTranslation();
  const footerTitle = title => {
    return title === 'business:u.c.solutionlinks.agile.face' ? (
      <AgileFaceText />
    ) : title === 'business:u.c.solutionlinks.agile.hand' ? (
      <AgileHandText />
    ) : (
      <Trans i18nKey={title} />
    );
  };
  const {location} = useReactRouter();
  const [showEnterprise, setShowEnterprise] = useState(SHOW_ENTERPRISE);
  const [showBtfv, setShowBtfv] = useState(SHOW_BTFV);
  const {isSignIn} = useContext(AuthContext);
  const [showResource, setShowResource] = useState(SHOW_RESOURCE);
  const [showTechnology, setShowTechnology] = useState(SHOW_TECHNOLOGY);
  const [showSMB, setSMB] = useState(SHOW_SMB);
  const resourceRef = useRef(null);
  let services = [
    serviceLinks.youcam4web,
    {...serviceLinks.sdk, showDivider: true},
    {...serviceLinks.instoreconsultation, showDivider: true},
    {...serviceLinks.applisting, showDivider: true},
    serviceLinks.youcam4google,
    serviceLinks.virtualmakeupsnapchat,
    {...serviceLinks.virtualmakeupinstagram, showDivider: true},
    serviceLinks.wechatminiprogram,
    serviceLinks.taobaominiprogram,
    // serviceLinks.facebookarchatbot,
    serviceLinks.douyinminiprogram,
  ];

  const resources = headerUtils.resource(i18n.language).filter(r => r);
  const technologies = headerUtils.techMenu.filter(r => r);
  const btfList = headerUtils.btflist(i18n.language).filter(btf => btf);
  let btfvList = headerUtils.btfvList(i18n.language).filter(btfv => btfv);
  if (webUtils.isMobile() && !webUtils.isChs(i18n.language)) {
    let lastElement = btfList.pop();
    if (i18n.language !== 'ja') {
      btfList.unshift(lastElement);
    } else {
      btfList.splice(1, 0, lastElement);
    }
  }
  if (webUtils.isChs(i18n.language)) {
    services.splice(2, 1);
  }

  const switchShowEnterprise = v => {
    SHOW_ENTERPRISE = v;
    setShowEnterprise(v);
  };

  const switchShowBTFV = v => {
    SHOW_BTFV = v;
    setShowBtfv(v);
  };

  const switchResource = v => {
    SHOW_RESOURCE = v;
    if (v && SHOW_ENTERPRISE) {
      SHOW_ENTERPRISE = false;
      setShowEnterprise(false);
    }
    setShowResource(v);
  };

  const switchTechnology = v => {
    SHOW_TECHNOLOGY = v;
    setShowTechnology(v);
  };

  const switchSmb = v => {
    SHOW_SMB = v;
    setSMB(v);
  };

  const goToDemostore = () => {
    const {functionType} = qr.parse(window.location.search);
    let url = '/business/showcase/lip-color';
    if (window.location.pathname.indexOf('/business/plan') !== -1) {
      url =
        functionType === 'EYEWEAR'
          ? '/business/showcase/online-service/eye-wear'
          : '/business/showcase/online-service/';
    }
    window.location.href = pathUtils.addLangPrefix(url);
  };

  const closeMenu = () => setPopupMenu(false);
  const isActive = path => location.pathname === pathUtils.addLangPrefix(path);
  const language = i18n.language
    ? i18n.language.toLowerCase().replace('_', '-')
    : 'en';

  return (
    <div className="pf-header-mobile-business-menus">
      <div className="pf-header-mobile-business-menus__item">
        <GoogleSearch is2b={true} />
      </div>
      <div
        className="pf-header-mobile-business-menus__item"
        onClick={() => switchShowEnterprise(!showEnterprise)}
      >
        <Trans i18nKey="business:c.l.s.popupmenu.enterprise">Enterprise</Trans>
        <ArrowIcon isShow={showEnterprise} color="grey" />
      </div>
      <div
        className={classnames({
          'mobile-business-menu': true,
          'pf-header-mobile-business-menus--collapse': !showEnterprise,
        })}
      >
        <div className="mobile-business-menu__title">
          <Trans i18nKey="business:p.b.s.e.s.menu.services">Services</Trans>
        </div>
        <div className="mobile-business-menu__subtitle">
          <Trans i18nKey="business:c.l.s.popupmenu.makeup">Makeup</Trans>
        </div>
        {!_isEmpty(headerUtils.makeupProducts) &&
          headerUtils.makeupProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.face.diagnostic">
            Face Diagnostic
          </Trans>
        </div>
        {!_isEmpty(headerUtils.faceDiaProducts) &&
          headerUtils.faceDiaProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.jewelry">
            Jewelry and Watches
          </Trans>
        </div>
        {!_isEmpty(headerUtils.jewelryWatchesProducts) &&
          headerUtils.jewelryWatchesProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.nail.color">Nail</Trans>
        </div>
        {!_isEmpty(headerUtils.nailProducts) &&
          headerUtils.nailProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.hair.color">Hair</Trans>
        </div>
        {!_isEmpty(headerUtils.hairProducts) &&
          headerUtils.hairProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.eyewear">Eyewear</Trans>
        </div>
        {!_isEmpty(headerUtils.eyewearProducts) &&
          headerUtils.eyewearProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.accessories">
            Accessories
          </Trans>
        </div>
        {!_isEmpty(headerUtils.accessoriesProducts) &&
          headerUtils.accessoriesProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <div
          className="mobile-business-menu__subtitle"
          style={{marginTop: '26px'}}
        >
          <Trans i18nKey="business:c.l.s.popupmenu.beard.dye">
            Men's grooming
          </Trans>
        </div>
        {!_isEmpty(headerUtils.bearddyeProducts) &&
          headerUtils.bearddyeProducts.map((item, i) => (
            <React.Fragment key={`${item.link}-${i}`}>
              <Link
                to={item.link}
                className={classnames({
                  'mobile-business-menu__item': true,
                  'mobile-business-menu__item--active': isActive(item.link),
                })}
                onClick={closeMenu}
              >
                <Trans i18nKey={item.i18nKey} />
              </Link>
            </React.Fragment>
          ))}
        <Link
          to="/business/solutions/enterprise"
          className="mobile-business-menu__viewall"
          onClick={() => closeMenu()}
        >
          <Trans i18nKey="business:p.b.s.e.s.view.all">View all products</Trans>
        </Link>
        <Link
          to="/business/contact-us/sales"
          className="mobile-business-menu__contact"
          onClick={() => closeMenu()}
        >
          <Trans i18nKey="business:c.l.f.menudefinitions.contact.sales">
            Contatct Sales
          </Trans>
        </Link>
        <div className="mobile-business-menu__divider" />
        <DemoStoreCard />
      </div>
      <div
        className="pf-header-mobile-business-menus__item"
        onClick={() => switchSmb(!showSMB)}
      >
        <Trans i18nKey="business:c.l.s.leftmenu.online.service">
          Online Service
        </Trans>{' '}
        <ArrowIcon isShow={showSMB} color="grey" />
      </div>
      {showSMB && <SmbMenus />}
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus--collapse': showResource,
          'pf-header-mobile-business-menus__item--opened': showTechnology,
        })}
        onClick={() => switchTechnology(!showTechnology)}
      >
        <Trans i18nKey="consumer:u.c.technologylinks.home">Technologies</Trans>
        <ArrowIcon isShow={showTechnology} color="grey" />
      </div>
      {!_isEmpty(technologies) &&
        technologies.map((m, i) => (
          <div
            key={'menu4-' + i}
            className={classnames({
              'pf-header-mobile-business-menus__subitem-category': true,
              'pf-header-mobile-business-menus__subitem-category-item--active': isActive(
                m.link
              ),
              'pf-header-mobile-business-menus--collapse':
                !showTechnology || showResource,
            })}
          >
            <Link to={m.link} onClick={closeMenu}>
              {footerTitle(m.i18nKey)}
            </Link>
          </div>
        ))}
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--active': isActive(
            '/business/showcase'
          ),
          'pf-header-mobile-business-menus--collapse': showResource,
        })}
        onClick={goToDemostore}
      >
        <Trans i18nKey="business:c.l.s.leftmenu.demo.store">Demo Store</Trans>
      </div>
      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--opened': showResource,
        })}
        onClick={() => {
          switchResource(!showResource);
        }}
        ref={resourceRef}
      >
        <Trans i18nKey="business:c.l.s.leftmenu.demo.resources">
          Resources
        </Trans>{' '}
        <ArrowIcon isShow={showResource} color="grey" />
      </div>
      <div
        className={classnames({
          'pf-header-mobile-business-menus__resources': true,
          'pf-header-mobile-business-menus--collapse': !showResource,
        })}
      >
        {}
        {!_isEmpty(resources) &&
          resources.map(res => (
            <Link
              key={res.id}
              to={res.link}
              className={classnames({
                'pf-header-mobile-business-menus__resource': true,
                'pf-header-mobile-business-menus__resource--active': isActive(
                  res.link
                ),
              })}
              onClick={closeMenu}
            >
              <Trans i18nKey={res.i18nKey} />
            </Link>
          ))}
        {btfList.map(btf =>
          i18n.language !== 'en' && btf.id === 'menu.business.btfVirtual' ? (
            <div
              key={btf.id}
              className={classnames({
                'pf-header-mobile-business-menus__resource': true,
                'pf-header-mobile-business-menus__resource--active': isActive(
                  '/business/global-beauty-tech-forum'
                ),
              })}
              onClick={() => switchShowBTFV(!showBtfv)}
            >
              <Trans i18nKey={btf.i18nKey} />
              {i18n.language !== 'en' &&
                btf.id === 'menu.business.btfVirtual' && (
                  <ArrowIcon
                    color="grey"
                    style={{marginLeft: '8px'}}
                    isShow={showBtfv}
                  />
                )}
            </div>
          ) : (
            <Link
              key={btf.id}
              to={btf.link}
              className={classnames({
                'pf-header-mobile-business-menus__resource': true,
                'pf-header-mobile-business-menus__resource--active': isActive(
                  btf.link
                ),
              })}
              onClick={closeMenu}
            >
              <Trans i18nKey={btf.i18nKey} />
            </Link>
          )
        )}
        {showBtfv && (
          <div style={{borderLeft: '2px solid #e5e5e5', marginLeft: '16px'}}>
            {btfvList.map(btfv => (
              <Link
                key={btfv.id}
                to={btfv.link}
                className={classnames({
                  'pf-header-mobile-business-menus__resource--active': isActive(
                    btfv.link
                  ),
                  'pf-header-mobile-business-menus__resource__sub': true,
                })}
                onClick={closeMenu}
                isNewTab={true}
              >
                <Trans i18nKey={btfv.i18nKey} />
              </Link>
            ))}
          </div>
        )}
        <div
          style={{
            borderBottom: '2px solid #e5e5e5',
            margin: '25px -10% 30px',
          }}
        />
        <div>
          <div className="pf-header-mobile-business-menus__featured">
            <Link
              to={fixedData.post[language] && fixedData.post[language].pathname}
              style={{
                backgroundImage:
                  fixedData.post[language] &&
                  `url(${fixedData.post[language].imageUrl})`,
              }}
              className="pf-header-mobile-business-menus__featured__img"
              onClick={closeMenu}
            />
            <div style={{width: '70%'}}>
              <div className="pf-header-mobile-business-menus__featured__type">
                <Trans i18nKey="business:c.l.s.resources.featured.post">
                  Featured Blog Post
                </Trans>
              </div>
              <Link
                to={
                  fixedData.post[language] && fixedData.post[language].pathname
                }
                className="pf-header-mobile-business-menus__featured__title"
                onClick={closeMenu}
              >
                {fixedData.post[language] && fixedData.post[language].title}
              </Link>
              <Link
                to="/business/blog"
                className="pf-header-mobile-business-menus__featured__link"
                onClick={closeMenu}
              >
                <Trans i18nKey="business:c.l.s.resources.more.blog.posts">
                  More Blog Posts
                </Trans>{' '}
                >
              </Link>
            </div>
          </div>
          <div className="pf-header-mobile-business-menus__featured">
            <Link
              to={
                fixedData.video[language] && fixedData.video[language].pathname
              }
              style={{
                backgroundImage:
                  fixedData.video[language] &&
                  `url(${fixedData.video[language].imageUrl})`,
              }}
              className="pf-header-mobile-business-menus__featured__img"
              onClick={closeMenu}
            />
            <div style={{width: '70%'}}>
              <div className="pf-header-mobile-business-menus__featured__type">
                <Trans i18nKey="business:c.l.s.resources.featured.video">
                  Featured Video
                </Trans>
              </div>
              <Link
                to={
                  fixedData.video[language] &&
                  fixedData.video[language].pathname
                }
                className="pf-header-mobile-business-menus__featured__title"
                onClick={closeMenu}
              >
                {fixedData.video[language] && fixedData.video[language].title}
              </Link>
              <Link
                to="/business/videos"
                className="pf-header-mobile-business-menus__featured__link"
                onClick={closeMenu}
              >
                <Trans i18nKey="business:c.l.s.resources.more.video">
                  More Videos
                </Trans>{' '}
                >
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classnames({
          'pf-header-mobile-business-menus__item': true,
          'pf-header-mobile-business-menus__item--active': isActive(
            '/business/investors'
          ),
          'pf-header-mobile-business-menus--collapse': showResource,
        })}
      >
        <Link to="https://ir.perfectcorp.com/" onClick={closeMenu}>
          <Trans i18nKey="business:c.l.s.rightmenu.investors">Investors</Trans>
        </Link>
      </div>
      {!isSignIn && (
        <div
          className={classnames({
            'pf-header-mobile-business-menus__item': true,
            'pf-header-mobile-business-menus__item--active': isActive(
              '/business/user/sign-in'
            ),
          })}
        >
          <Link to="/business/user/sign-in" onClick={closeMenu}>
            <Trans i18nKey="business:c.l.s.rightmenu.sign.in">Sign In</Trans>
          </Link>
        </div>
      )}
    </div>
  );
}
