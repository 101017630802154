import React from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import webUtils from '../../utils/webUtils';
import _startsWith from 'lodash/startsWith';
import {useTranslation} from 'react-i18next';

export default function PFLink({
  to,
  hash,
  isBusiness,
  linkRef,
  children,
  isNewTab = false,
  reload,
  rel,
  notLink = false,
  isYce = false,
  yceKeyword = '',
  key,
  ...props
}) {
  const {i18n} = useTranslation();
  const language = webUtils
    .determineLanguage(i18n.language, isBusiness)
    .replace('_', '-')
    .toLowerCase();
  const langParam = language === 'en' ? '' : `/${language}`;
  const yceKeywordParam = yceKeyword === '' ? '' : `/${yceKeyword}`;
  if (isYce) {
    return (
      <a
        key={key}
        target={'_blank'}
        href={`https://yce.perfectcorp.com${langParam}${yceKeywordParam}?utm_source=PF_site&utm_medium=PF_home`}
        {...props}
      >
        {children}
      </a>
    );
  }

  if (hash) {
    return (
      <HashLink
        key={key}
        to={`${langParam}${to}#${hash}`}
        ref={linkRef}
        {...props}
      >
        {children}
      </HashLink>
    );
  }
  if (notLink) {
    return (
      <div key={key} ref={linkRef} {...props}>
        {children}
      </div>
    );
  }

  if (
    to &&
    to.indexOf('#') !== -1 &&
    `${langParam}${to}`.indexOf(`${window.location.pathname}`) !== -1
  ) {
    return (
      <HashLink to={`${langParam}${to}`} ref={linkRef} {...props} key={key}>
        {children}
      </HashLink>
    );
  }

  if (hash) {
    return (
      <HashLink
        to={`${langParam}${to}#${hash}`}
        ref={linkRef}
        {...props}
        key={key}
      >
        {children}
      </HashLink>
    );
  }

  if (!to) {
    return (
      <Link to="" ref={linkRef} {...props} key={key}>
        {children}
      </Link>
    );
  }

  if (_startsWith(to, 'http') || reload) {
    return (
      <a
        key={key}
        href={to}
        ref={linkRef}
        {...props}
        rel={rel ? rel : 'noopener noreferrer'}
        target={isNewTab ? '_blank' : '_self'}
      >
        {children}
      </a>
    );
  }

  if (to && to.indexOf('/business/plan') > -1) {
    return (
      <a
        key={key}
        target={isNewTab ? '_blank' : '_self'}
        rel={rel && rel}
        href={`${langParam}${to}`}
        ref={linkRef}
        {...props}
      >
        {children}
      </a>
    );
  }

  if (to && to.indexOf('/business/investors') > -1) {
    return (
      <a
        key={key}
        target={isNewTab ? '_blank' : '_self'}
        rel={rel && rel}
        href={`${langParam}${to}`}
        ref={linkRef}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      key={key}
      target={isNewTab ? '_blank' : '_self'}
      rel={rel && rel}
      to={`${langParam}${to}`}
      ref={linkRef}
      {...props}
    >
      {children}
    </Link>
  );
}
