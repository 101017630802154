import React from 'react';
import {Trans} from 'react-i18next';
import AgileFaceText from '../../pages/business/technologies/agileface/RegisteredName';
import AgileHandText from '../../pages/business/technologies/agilehand/component/registeredname/index';
import webUtils from '../webUtils';

const links = {
  makeup: {
    id: 'c.l.s.leftmenu.online.service.makeup',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.makeup">
        Virtual Makeup Try-On
      </Trans>
    ),
    link: '/business/solutions/online-service/makeup',
    i18nKey: 'business:c.l.s.leftmenu.online.service.makeup',
    show: true,
    activeLink: '/business/solutions/online-service/makeup',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card1.desc">
        Add MAKEUP TRY ON button on your site using our web module and web
        widget
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.makeup.card.desc">
        <span>Makeup</span> Virtual Try On
      </Trans>
    ),
    solutionStyle: 'makeup',
  },
  eyewear: {
    id: 'c.l.s.leftmenu.online.service.eyewear',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.eyewear">
        Virtual Glasses Try-On
      </Trans>
    ),
    link: '/business/solutions/online-service/eye-wear',
    i18nKey: 'business:c.l.s.leftmenu.online.service.eyewear',
    show: true,
    activeLink: '/business/solutions/online-service/eye-wear',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card2.desc">
        Integrate our GLASSES TRY ON module or widget for better sales
        conversion
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.eyewear.card.desc">
        <span>Glasses</span> Virtual Try On
      </Trans>
    ),
    solutionStyle: 'eyewear',
  },
  skincare: {
    id: 'c.l.s.leftmenu.online.service.skincare',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.skincare">
        Skincare Pro
      </Trans>
    ),
    link: '/business/solutions/online-service/skincare-pro',
    i18nKey: 'business:c.l.s.leftmenu.online.service.skincare',
    show: !webUtils.isCnDomain(),
    activeLink: '/business/solutions/online-service/skincare-pro',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card3.desc">
        Software-base SKIN SCANNER solution on iPads with customizable skincare
        recommendations
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.skin.analysis.card.desc">
        <span>AI Skin Analysis</span>
      </Trans>
    ),
    solutionStyle: 'skin-analysis',
  },
  aesthetic: {
    id: 'c.l.s.leftmenu.online.service.aesthetic.simulation',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.aesthetic.simulation">
        aesthetic.simulation
      </Trans>
    ),
    link: '/business/solutions/online-service/aesthetic-simulation',
    i18nKey: 'business:c.l.s.leftmenu.online.service.aesthetic.simulation',
    show: !webUtils.isCnDomain(),
    activeLink: '/business/solutions/online-service/aesthetic-simulation',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card4.desc">
        Software-base SKIN SCANNER solution on iPads with customizable skincare
        recommendations
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.skin.simulation.card.desc">
        <span>AI Aesthetic Simulation</span>
      </Trans>
    ),
    solutionStyle: 'skin-simulation',
  },
};

export default links;
