import React from 'react';
import './index.css';
import webUtils from '../../utils/webUtils';

export default function FloatingBtns() {
  const floatingBtnData = [
    {
      id: 'email',
      icon: 'icon_fixed-1.svg',
      link: '/zh-cn/business/contact-us/sales',
      title: '联络我们',
    },
    {
      id: 'phone',
      icon: 'icon_fixed-2.svg',
      title: '电话：021-64225908',
    },
    {
      id: 'wechat',
      icon: 'icon_fixed-3.svg',
      title: '企业微信',
      img: require('../../assets/images/icon/wechat_qrcode.png'),
      imgSize: 183,
    },
  ];

  const handleButtonClick = link => {
    if (link) {
      window.location.href = link;
    }
  };

  // only show in desktop
  if (webUtils.isMobile() || webUtils.isPad()) {
    return <></>;
  }

  return (
    <div className="cn-btn--container">
      {floatingBtnData.map(button => (
        <div className="cn-btn-set" key={button.id}>
          <div
            className={`cn-contact-sales-${button.id}-btn`}
            onClick={() => handleButtonClick(button.link)}
          />
          <div className={`cn-contact-sales-${button.id}-desc`}>
            {button.title}
            {button.img && (
              <img
                alt={button.id}
                width={button.imgSize}
                height={button.imgSize}
                src={button.img}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
