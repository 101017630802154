import React, {useEffect, useState} from 'react';
import authDao, {cache} from '../../dao/authDao';

export const AuthContext = React.createContext({
  accountSummary: null,
  isFirstLoaded: false,
  isLoaded: false,
  isSignIn: false,
});

export const withAuth = Component => props => (
  <AuthContext.Consumer>
    {value => <Component {...props} auth={value} />}
  </AuthContext.Consumer>
);

export default function Auth({children}) {
  const [isFirstLoaded, setIsFirstLoaded] = useState(cache.isFirstLoaded);
  const [isLoaded, setIsLoaded] = useState(!!cache.accountSummary);
  const [accountSummary, setAccountSummary] = useState(cache.accountSummary);

  useEffect(() => {
    if (!accountSummary) {
      authDao
        .loadAuth()
        .then(json => {
          cache.accountSummary = {...json};
          cache.isFirstLoaded = true;

          setIsFirstLoaded(true);
          setIsLoaded(true);
          setAccountSummary(json);
        })
        .catch(() => {
          cache.isFirstLoaded = true;

          setIsFirstLoaded(true);
          setIsLoaded(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        accountSummary,
        isFirstLoaded,
        isLoaded,
        isSignIn: !!accountSummary,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
