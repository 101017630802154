import React, {useEffect, useState} from 'react';
import './index.css';
import useWindowScroll from '../hooks/useWindowScroll';
import classnames from 'classnames';
import webUtils from '../../utils/webUtils';

export default function GoDemoButton() {
  const scrollTop = useWindowScroll();
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (scrollTop > 0 && !isScroll) {
      setIsScroll(true);
    }
  }, [scrollTop]);

  return (
    <div
      className={classnames({
        'pf-language': true,
        'go-demo-btn--container': true,
        'go-demo-btn--container--active': isScroll,
      })}
      onClick={webUtils.goToDemostore}
    >
      <div className="go-demo-text">
        <div>TRY-ON</div>
      </div>
    </div>
  );
}
