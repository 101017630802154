import React from 'react';
import Async from './async';
import BlankLayout from '../../../../components/layout/BlankLayout';

export default [
  <BlankLayout
    key="/nft/try-on/:lookGuid?"
    exact
    path="/nft/try-on/:lookGuid?"
    component={Async}
  />,
];
