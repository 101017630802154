import React from 'react';
import i18n from '../i18n';
import {Trans} from 'react-i18next';

const configs = {
  appTypes: [
    {
      value: 'YMK',
      label: <Trans i18nKey="business:contactus.app.types.ymk" />,
    },
    {
      value: 'Y4B',
      label: <Trans i18nKey="business:contactus.app.types.y4b" />,
    },
    {
      value: 'YCP',
      label: <Trans i18nKey="business:contactus.app.types.ycp" />,
    },
    {
      value: 'YCV',
      label: <Trans i18nKey="business:contactus.app.types.ycv" />,
    },
    {
      value: 'YCC',
      label: <Trans i18nKey="business:contactus.app.types.ycc" />,
    },
    {
      value: 'YCN',
      label: <Trans i18nKey="business:contactus.app.types.ycn" />,
    },
    {
      value: 'YCF',
      label: <Trans i18nKey="business:contactus.app.types.ycf" />,
    },
  ],
  productTypes: [
    {
      value: 'P01_VIRTUAL_MAKEUP',
      label: <Trans i18nKey="business:u.c.solutionlinks.virtual.makeup" />,
      keyword: 'virtual-makeup',
    },
    {
      value: 'P02_AI_SMART_SHADE_FINDER',
      label: <Trans i18nKey="business:u.c.solutionlinks.ai.shade.finder" />,
      keyword: 'shade-finder',
    },
    {
      value: 'P03_AI_FACIAL_RECOGNITION',
      label: (
        <Trans i18nKey="business:u.c.solutionlinks.ai.facial.recognition" />
      ),
      keyword: 'facial-recognition',
    },
    {
      value: 'P04_AR_SKIN_DIAGNOSTIC',
      label: <Trans i18nKey="business:u.c.solutionlinks.ai.skin.diagnostic" />,
      keyword: 'skin-diagnostic',
    },
    {
      value: 'P05_IN_STORE_BARCODE_TRY_ON',
      label: (
        <Trans i18nKey="business:contactus.product.types.in.store.barcode.try.on" />
      ),
      keyword: 'barcode',
    },
    {
      value: 'P06_BEAUTY_ADVISOR_1_ON_1',
      label: <Trans i18nKey="business:u.c.solutionlinks.ba.1.on.1" />,
      keyword: '1-on-1',
    },
    {
      value: 'P07_LIVE_AR_FOR_CORPORATE_TRAINING',
      label: <Trans i18nKey="business:u.c.solutionlinks.live.ar.training" />,
      keyword: 'training',
    },
    {
      value: 'P08_LIVE_STREAMING_FOR_BRANDS',
      label: <Trans i18nKey="business:u.c.solutionlinks.live.streaming" />,
      keyword: 'streaming',
    },
    {
      value: 'P09_LIVE_HAIR_COLOR',
      label: <Trans i18nKey="business:u.c.solutionlinks.live.hair.color" />,
      keyword: 'hair-color',
    },
    {
      value: 'P10_VIRTUAL_ACCESSORIES',
      label: <Trans i18nKey="business:u.c.solutionlinks.virtual.accessories" />,
      keyword: 'virtual-accessories',
    },
    {
      value: 'P11_PRODUCT_RECOMMENDATION',
      label: (
        <Trans i18nKey="business:contactus.product.types.product.recommendation" />
      ),
      keyword: 'recommendation',
    },
    {
      value: 'P12_COLOR_REPORT',
      label: <Trans i18nKey="business:contactus.product.types.color.report" />,
      keyword: 'color-report',
    },
  ],
  employeeNumbers: [
    {
      value: 'C050',
      label: <Trans i18nKey="business:contactus.employee.num.50" />,
    },
    {
      value: 'C100',
      label: <Trans i18nKey="business:contactus.employee.num.100" />,
    },
    {
      value: 'C200',
      label: <Trans i18nKey="business:contactus.employee.num.200" />,
    },
    {
      value: 'C300',
      label: <Trans i18nKey="business:contactus.employee.num.300" />,
    },
    {
      value: 'C301500',
      label: <Trans i18nKey="business:contactus.employee.num.301" />,
    },
  ],
  employeeNumbers4ContactUs: [
    {
      value: 'C050',
      label: <Trans i18nKey="business:contactus.employee.num.50" />,
    },
    {
      value: 'C100',
      label: <Trans i18nKey="business:contactus.employee.num.100" />,
    },
    {
      value: 'C200',
      label: <Trans i18nKey="business:contactus.employee.num.200" />,
    },
    {
      value: 'C300',
      label: <Trans i18nKey="business:contactus.employee.num.300" />,
    },
    {
      value: 'C301500',
      label: <Trans i18nKey="business:contactus.employee.num.301" />,
    },
    {
      value: 'C999',
      label: <Trans i18nKey="business:contactus.employee.num.999" />,
    },
  ],
  businessTypes: [
    {
      value: 'MAKEUP',
      label: <Trans i18nKey="business:contactus.business.type.makeup" />,
    },
    {
      value: 'SKINCARE',
      label: <Trans i18nKey="business:contactus.business.type.skincare" />,
    },
    {
      value: 'MEDSPACLINIC',
      label: <Trans i18nKey="business:contactus.business.type.medspa.clinic" />,
    },
    {
      value: 'JEWELRYWATCHES',
      label: (
        <Trans i18nKey="business:contactus.business.type.jewelry.watches" />
      ),
    },
    {
      value: 'EYEWEAR',
      label: <Trans i18nKey="business:contactus.business.type.eyewearbanner" />,
    },
    {
      value: 'HAIRRELATED',
      label: <Trans i18nKey="business:contactus.business.type.hair.related" />,
    },
    {
      value: 'NAILS',
      label: <Trans i18nKey="business:contactus.business.type.nails" />,
    },
    {
      value: 'COLORLENS',
      label: <Trans i18nKey="business:contactus.business.type.color.lenses" />,
    },
    {
      value: 'AGENCY',
      label: <Trans i18nKey="business:contactus.business.type.agency" />,
    },
    {
      value: 'MEDIA',
      label: <Trans i18nKey="business:contactus.business.type.media" />,
    },
    {
      value: 'OTHERS',
      label: <Trans i18nKey="business:contactus.business.type.others" />,
    },
  ],
  freeTrialBusinessTypes: [
    {
      value: 'BRAND',
      label: <Trans i18nKey="business:contactus.business.type.brand" />,
    },
    {
      value: 'RETAILER',
      label: <Trans i18nKey="business:contactus.business.type.retailer" />,
    },
    {
      value: 'MANUFACTURER',
      label: <Trans i18nKey="business:contactus.business.type.manufacturer" />,
    },
    {
      value: 'DIGITALAGENCY',
      label: (
        <Trans i18nKey="business:contactus.business.type.digital.agency" />
      ),
    },
    {
      value: 'SYSTEMINTEGRATOR',
      label: (
        <Trans i18nKey="business:contactus.business.type.system.integrator" />
      ),
    },
    {
      value: 'OTHERS',
      label: <Trans i18nKey="business:contactus.business.type.others" />,
    },
  ],
  skincareBusinessTypes: [
    {
      value: 'BRAND',
      label: <Trans i18nKey="business:contactus.business.type.brand" />,
    },
    {
      value: 'WELLNESSCENTER',
      label: (
        <Trans i18nKey="business:contactus.business.type.wellness.clinic" />
      ),
    },
    {
      value: 'MEDICALSPA',
      label: (
        <Trans i18nKey="business:contactus.business.type.medical.clinic" />
      ),
    },
    {
      value: 'FACIALBAR',
      label: <Trans i18nKey="business:contactus.business.type.facial.bar" />,
    },
    {
      value: 'DAYSPA',
      label: <Trans i18nKey="business:contactus.business.type.day.spa" />,
    },
    {
      value: 'DERMATOLOGYPRATICE',
      label: (
        <Trans i18nKey="business:contactus.business.type.dermatology.practice" />
      ),
    },
    {
      value: 'PLASTICSURGERYPRATICE',
      label: (
        <Trans i18nKey="business:contactus.business.type.plastic.surgery.practice" />
      ),
    },
    {
      value: 'PHYSICIANPRACTICE',
      label: (
        <Trans i18nKey="business:contactus.business.type.other.specialty" />
      ),
    },
  ],
  hearFrom: [
    {
      value: 'EMAIL_NEWSLETTER',
      label: <Trans i18nKey="business:contactus.hear.from.email.newsletter" />,
    },
    {
      value: 'WEB_SEARCH',
      label: <Trans i18nKey="business:contactus.hear.from.web.search" />,
    },
    {
      value: 'SOCIAL_MEDIA',
      label: <Trans i18nKey="business:contactus.hear.from.social.media" />,
    },
    {
      value: 'WEBINAR_EVENTS',
      label: <Trans i18nKey="business:contactus.hear.from.webinar.events" />,
    },
    {
      value: 'ADVERTISEMENT',
      label: <Trans i18nKey="business:contactus.hear.from.advertisement" />,
    },
    {
      value: 'FRIEND_PARTNER',
      label: <Trans i18nKey="business:contactus.hear.friend.partner" />,
    },
    {
      value: 'OTHER',
      label: <Trans i18nKey="business:contactus.hear.from.other" />,
    },
  ],
  convertEmployee: value => {
    switch (value) {
      case 'C050':
        return 'R_1_50';
      case 'C100':
        return 'R_51_100';
      case 'C200':
        return 'R_101_200';
      case 'C300':
        return 'R_201_300';
      case 'C301500':
        return 'R_301_500';
      case 'C999':
        return 'R_501_999';
      default:
        return 'R_1_50';
    }
  },
  employeeMore200: value => {
    switch (value) {
      case 'C050':
        return false;
      case 'C100':
        return false;
      case 'C200':
        return false;
      case 'C300':
        return true;
      case 'C301500':
        return true;
      case 'C999':
        return true;
      default:
        return true;
    }
  },
  getQuestionTypes: () => [
    {
      label: <Trans i18nKey="business:contactus.question.type.account" />,
      options: [
        {
          value: 'Q01_ACCOUNT_MANAGEMENT',
          label: (
            <Trans i18nKey="business:contactus.question.type.account.management" />
          ),
        },
        {
          value: 'Q02_ACCOUNT_BILLING_INFORMATION',
          label: (
            <Trans i18nKey="business:contactus.question.type.account.billing.info" />
          ),
        },
      ],
    },
    {
      label: <Trans i18nKey="business:contactus.question.type.purchase" />,
      options: [
        {
          value: 'Q03_PURCHASE_PAYMENT',
          label: (
            <Trans i18nKey="business:contactus.question.type.purchase.payment" />
          ),
        },
        {
          value: 'Q04_PURCHASE_PRODUCT_INFORMATION',
          label: (
            <Trans i18nKey="business:contactus.question.type.purchase.product.info" />
          ),
        },
      ],
    },
    {
      label: <Trans i18nKey="business:contactus.question.type.feature" />,
      options: [
        {
          label: (
            <Trans i18nKey="business:contactus.question.type.feature.howto" />
          ),
          value: 'Q05_FEATURE_HOWTO',
        },
        {
          label: (
            <Trans i18nKey="business:contactus.question.type.feature.issues" />
          ),
          value: 'Q06_FEATURES_ISSUES',
        },
      ],
    },
    {
      label: <Trans i18nKey="business:contactus.question.type.others" />,
      options: [
        {
          label: <Trans i18nKey="business:contactus.question.type.others" />,
          value: 'Q07_OTHERS',
        },
      ],
    },
  ],
};

export default configs;
