import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Link from '../../../../link';
import './index.css';
import ArrowLink from '../../../../arrowlink';

export default function DemoStoreCard() {
  const {i18n} = useTranslation();
  const lang =
    i18n.language === 'en' || !i18n.language
      ? ''
      : `/${i18n.language.replace('_', '-').toLowerCase()}`;
  const demostoreConfigs = [
    {
      cover:
        'https://plugins-media.makeupar.com/smb/story/2023-01-30/d10fc4b5-6c41-4e1c-9650-9cd1e410ee1c.png',
      label: (
        <Trans i18nKey="business:p.b.s.e.s.menu.makeup.tryon">
          Makeup Try-on
        </Trans>
      ),
      link: '/business/showcase/lip-color',
    },
    {
      cover:
        'https://plugins-media.makeupar.com/smb/story/2023-01-30/f7b216f9-b454-48ac-8123-afaa4f76fd61.png',
      label: (
        <Trans i18nKey="business:p.b.s.e.s.menu.skin.analysis">
          Skin Analysis
        </Trans>
      ),
      link: '/business/showcase/skincare/home',
    },
    {
      cover:
        'https://plugins-media.makeupar.com/smb/story/2023-01-30/92c0a73f-6f9e-4f05-8821-0b864bce31e8.png',
      label: (
        <Trans i18nKey="business:p.b.s.e.s.menu.ai.face.analysis">
          AI Face Analyzer
        </Trans>
      ),
      link: '/business/showcase/face-analyzer',
    },
    {
      cover:
        'https://plugins-media.makeupar.com/smb/story/2023-01-30/4c691888-1d7e-44e8-8908-556214ae0f9b.png',
      label: (
        <Trans i18nKey="business:p.b.s.e.s.menu.ring.tryon">Ring Try-on</Trans>
      ),
      link: '/business/showcase/rings',
    },
  ];

  return (
    <>
      <div className="pf-subheader-pop-menu-title">
        <Trans i18nKey="business:p.b.s.e.s.menu.demo.store">Demo Store</Trans>
      </div>
      <div className="pf-subheader-pop-menu-desc">
        <Trans i18nKey="business:p.b.s.e.s.menu.demo.store.desc">
          Experience the future of beauty and fashion with AI & AR! Create
          incredible shopping experiences that draw your customers in, bringing
          you closer together.
        </Trans>
      </div>
      <div className="pf-subheader-demostore-card--container">
        {demostoreConfigs.map((s, i) => (
          <Link
            reload={true}
            to={lang + s.link}
            className="pf-subheader-demostore-card"
          >
            <img
              className="pf-subheader-demostore-card-cover"
              alt=""
              src={s.cover}
            />
            <div className="pf-subheader-demostore-card-text">
              <div>{s.label}</div>
              <img
                alt=""
                src="https://plugins-media.makeupar.com/smb/story/2023-01-30/4308e7d9-88cb-46f4-9872-3f5415f6e8d0.png"
              />
            </div>
          </Link>
        ))}
      </div>
      <div className="pf-subheader-pop-menu-footer">
        <ArrowLink
          link={lang + '/business/showcase/lip-color'}
          reload={true}
          title={
            <Trans i18nKey="business:p.b.s.e.s.menu.demo.store.try.all">
              Try All Demo
            </Trans>
          }
        />
      </div>
    </>
  );
}
