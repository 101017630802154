import React, {useEffect, useState} from 'react';
import './index.css';
import Link from '../link';
import classnames from 'classnames';
import useWindowScroll from '../hooks/useWindowScroll';
import _defer from 'lodash/defer';

export default function GoContactButton({
  contactsalesbutton,
  scrollTopThreshold = 0,
  style,
  displayNoneOnHidden,
  ...props
}) {
  const scrollTop = useWindowScroll();
  const [enabled, setEnabled] = useState(false);
  const [isVisible, setIsVisible] = useState(enabled);

  const handleTransitionEnd = e => {
    props.onTransitionEnd && props.onTransitionEnd(e);
    const computedStyle = e.target && window.getComputedStyle(e.target);
    if (
      computedStyle &&
      (computedStyle.getPropertyValue('opacity') === '0' ||
        computedStyle.getPropertyValue('visibility') === 'hidden' ||
        computedStyle.getPropertyValue('display') === 'none')
    ) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const enabled = scrollTop > scrollTopThreshold;
    enabled && setIsVisible(true);
    _defer(() => setEnabled(enabled));
  }, [scrollTop, scrollTopThreshold]);

  return (
    <div>
      {contactsalesbutton && (
        <Link
          className={classnames({
            'go-contact-btn': true,
            'go-contact-btn--active': enabled,
          })}
          to={'/business/contact-us/sales'}
          style={
            displayNoneOnHidden && !isVisible
              ? {...style, display: 'none'}
              : style
          }
          onTransitionEnd={handleTransitionEnd}
          {...props}
        />
      )}
    </div>
  );
}
