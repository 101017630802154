import React from 'react';
import Async from './async';
import DefaultNFTLayout from '../../../../components/layout/DefaultNFTLayout';

export default [
  <DefaultNFTLayout
    key="/nft/detail"
    exact
    path="/nft/detail/:tokenId"
    component={Async}
  />,
];
